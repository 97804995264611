.header{
    display: flex;
    width: 100%;
    height: 5rem;
    z-index: 1000;
    position: fixed;
    justify-content: space-between;
    background-color: var(--ion-header-bg);
}

@media only screen and (min-width: 762px){
    .header{
        display: none;
    }
}
.header-brand{
    display: flex;
    width: 40%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.1rem;
    
}

.header-brand img{
    width: 100%;
    height: 60%;
    object-fit: contain;
}

.toggle{
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.darkmode{
    font-size: 12px;
    font-weight: bold;
    padding-top: 10px;
}
