.search-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding-top: 2rem;
}

.search-recent{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 5px auto;
    height:auto;


}
.search-recent-container{
    display: flex;
    height: 25px;
    width: 40%;
    align-items: center;
    margin-top: 5px;
}
.search-recent-left{
    display: flex;
    align-items: center;
    font-size: larger;
    cursor: pointer;
}
.search-recent-left:hover{
    color: red;
}
.search-recent-right{
    display: flex;
    width: 90%;
    text-align: start;
    margin-left: 20px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    cursor: pointer;
}

.search-body{
    display: flex;
    flex-direction: column;
    width: 100%;
    height:calc( 100vh - 3rem );
    overflow-y: hidden;
}

.search-header{
    display: flex;
    width: 90%;
    background-color: var(--feed-bg-color);
    box-shadow: 1px 2px 5px solid grey;
    border: 1px solid grey;
    border-radius: 5px;
    margin: auto;
    align-items: center;
    height: 3rem;
}

.search-feed{
    display: flex;
    align-items: center;
    width: 85%;
    margin: 2rem auto;
    border: 1px solid var(--ion-text-color);
    cursor: pointer;
}

.search-feed-img{
    display: flex;
    align-items: center;
    width: 20%;
    height: 80%;
}
.search-feed-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.search-feed-content{
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 100%;
    padding-inline: 10px;
    font-weight: bold;
}
.search-feed-footer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-block: 10px;
    color: grey;
    font-size: 75%;
}