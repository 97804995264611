ion-menu {
  display: flex !important;
  width: 100% !important;
  background-color: red !important;
  height: 100vh;
  /* height: 90vh !important; */
  /* --background: var(--ion-item-background, var(--ion-background-color, #fff)); */
  display: flex;
  margin: auto auto;
  border: none !important;
  /* border-radius: 20px; */
}

ion-menu.md ion-content {
  border-bottom: none !important;
}

ion-menu.md ion-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 3rem;
  padding-inline: 10px;
  border-bottom: none !important;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  color: #757575;
  min-height: 26px;
}
ion-split-pane {
  --side-max-width: 18%;
  --side-min-width: 300px;
  
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-dark-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-dark);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-dark);
} 


.logo{
  display: flex;
  width: 100%;
  height: 10%;
  justify-content: flex-start;
  align-items: flex-start;
}

.logo img{
  width: 50%;
  height: 70%;
  object-fit: contain;
}

.menu-custom-items{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
}
.toggle-custom{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.menu-bkmrk-notification{
  position: absolute;
  right: 40%;
  top: 10px;
  font-size: small;
  color: rgb(237, 88, 29);
}
.darkmode-text{
  padding-left: 10px;
}