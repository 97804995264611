.error-container{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.logo{
    display: flex;
    width: 70%;
    height: 30%;
    justify-content: center;
    align-items: center;
}

.logo img{
    width: 100%;
    height: 70%;
    object-fit: contain;

}

.message{
    display: flex;
    width: 80%;
    height: 10%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(51, 8, 8);
    font-size: 1.5rem;
    font-weight: bold;
}