.signupPage {

    ion-toolbar {

        --border-style: none;
        --border-color: transparent;
        --padding-top: 1rem;
        --padding-bottom: 1rem;
        --padding-start: 1rem;
        --padding-end: 1rem;
    }
}

.loading-signup{
    display: flex;
    background-color: rgba(0, 0, 0, 0.726);
    z-index: 1000000;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.headingText {

    h5 {

        margin-top: 0.2rem;
        color: #d3a6c7;
    }
}


.signup-login-btn{
    cursor: pointer;
    padding-left: 5px;
    font-weight: bold;
}


.signup-back-btn{
    padding-block: 1rem;
    cursor: pointer;
}

.signup_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: "light"
}

.signup-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: var(--ion-color-light);
    overflow-y: scroll;
}

.radio-label{
    display: flex;
    width: fit-content;
    padding-inline: 5px;
    color: var(--ion-color-dark);
}
.signup-success{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.success-header{
    display: flex;
}
.dataset-checkbox{
    display: none;
    width: fit-content;
    margin-left: auto;
}