.landing_container{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: var(--ion-color-light);
}
.landing-logo{
    display: flex;
    width: 70%;
    height: 50%;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 500px) {
    .landing-logo{
        width: 300px;
        height: 200px;
    }
    .landing-logo img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.landing-btn{
    display: flex;
    flex-direction: column;
    width: 70%;
    height: auto;
    justify-content: center;
    align-items: center;
    
}
.landing-btn > button{
    display: flex;
    padding-inline: 40px;
    padding-block: 15px;
    border-radius: 100px;
    font-size: 17px;
    font-weight: 700;
    margin-block: 15px;
    color: var(--ion-color-light);
    background: var(--ion-color-dark);
}

.landing-btn > button:focus{
}

