.accounts-top{
    display: flex;
    width: 100%;
    height: 10vh;
    align-items: center;
    padding-left: 20px;
    font-weight: bold;
    font-size: 20px;
}
.accounts-menu{
    display: flex;
    width: 100%;
    height: 90vh;
    background-color: var(--ion-background-color)
}