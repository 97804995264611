.feed-header-posttype{
  
}


ion-app{
  display: flex !important;
  justify-content: center !important;
}

ion-item.account-item {
  --ion-item-background: var(--ion-background-color);
}

ion-modal.fullscreen-modal{
  --display:flex;
  --align-items: center;
  --justify-content: center;
  --height:fit-content;
  --border-radius: 0px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.873);
  

}
tr th{
  border-bottom: none !important;
}

td{
  border: none !important;
}
.css-19kzrtu{
  padding: 0;
}
ion-modal {
    --height: 100%;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    
}
ion-page.main-container{
  display: flex;
  --background: rgb(95, 82, 82);
}
.app-container{
  display: flex;
  width: 100%;
  margin: auto;
}

/* ion-menu.md ion-item ion-label.ionlabel-toggle{
  --color: var(--ion-text-color)
} */

  @media only screen and (min-width: 762px){
    ion-modal {
        --height: 90%;
        --width: 80%;
        --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
        
    }
  }



ion-radio-group.radio-group{
    display: flex;
    
}
ion-item.ion-custom-item{
  display: flex;
  width: 100%;
  --background: var(--ion-color-light);
  
}

ion-item.ion-custom-item input{
  display: flex;
  width: fit-content;
  margin-left: auto;
}

ion-radio{
  display: flex;
  width: fit-content;
  margin-left: auto;
}
ion-col.signup-col{
  margin: auto;
}

ion-label.radio-label{
  padding-inline: 0.4rem;
  color: var(--ion-color-light)
}


@media only screen and (max-width: 900px){
  ion-radio-group.radio-group{
    display: block;
  }
  .app-container{
    display: flex;
    width: 100%;
    margin-top: 5rem;
  }

}

ion-button.custom-button{
  --background: var(--ion-color-dark);
  color: var(--ion-color-light)
}

ion-grid.signup-grid{
  width: 100%;
  overflow-y: scroll;
  margin-top: 2rem;
  
}

/* ion-app.main-router-app{
  --background: 'grey'
} */


ion-list.account-list{
  --background: var(--ion-background-color);
}





.swiper-slide img{
  display: flex;
  position: relative;
  width: 100%;
  max-height: 600px;
  object-fit: contain;
}






  /* Dark theme */
