/* @import url('https://fonts.googleapis.com/css2?family=Sora:wght@600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Lora:ital,wght@0,400;1,400;1,500&family=Sora:wght@300;400;500;600;700&display=swap');
* {

    font-family: 'Sora', sans-serif;
    font-size: small;
}


.header_title{
    font-size: 20px;
    padding-inline: 20px;
}
.header-app{
    position: fixed;
    height: 5rem;
    top: 0;
    width: 100%;
    z-index: 100000;
    margin-bottom: 5rem;
}
.app-container{
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: black;
}

@media only screen and (max-width: 768px){
    .app-container{
        margin-top: 5rem;
    }
}