.singlepost-error{
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.singlepost_container{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    overflow-y: scroll;
}
.singlepost_header{
    display: flex;
    height: 10%;
    align-items: center;
}

.singlepost_post{
    display: flex;
    width: 90%;
    height: 100%;
    margin: auto;
    overflow-y: scroll;
}
.singlepost-modal{
    display: flex;
    flex-direction: column;
    margin: auto 0px;
    background-color: inherit;
}

/* ion-modal.fullscreen-modal-singlepost{} */

.singlepost-modal{
    display: flex;
    max-height: 70vh;
    align-items: flex-start;
    overflow-y: scroll;
}