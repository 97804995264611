
.feed-container{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin: 10px auto;
    align-items: center;
    background: var(--feed-bg-color);
    border: 2px solid var(--ion-color-step-50);

}
.expand-charts{
    display: none;
}
.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
    
  }
.MuiTab-textColorInherit {
    color: var(--ion-color-text) !important;
    opacity: 0.7;
}
.MuiBox-root-86{
    padding:5px !important
}
.MuiBox-root{
    padding:10px !important
}
.MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
    border-color: aliceblue !important;
}
.MuiAppBar-colorInherit{
    background-color: var(--feed-bg-color) !important;
    color: green !important;
}
@media only screen and (min-width: 300px) and (max-width: 590px){
    .feed-container{
        width: 100%;
    }
}


@media only screen and (min-width: 590px) and (max-width: 762px){
    .feed-container{
        width: 80%;
    }
}
@media only screen and (min-width: 762px){
    .feed-container{
        width: 800px;
        border-radius: 20px;
    }
    .expand-charts{
        display: flex;
        position: absolute;
        z-index: 100;
        
        top: 15%;
        right: 5%;
        transition: transform 0.2s ease-in-out;
    }
    .expand-charts:hover{
        transform: scale(1.5);
        transition: transform 0.2s ease-in-out;
    }
}


.feed-video-play-btn{
    display: flex;
    position: absolute;
    z-index: 10000;
    top: 50%;
    left: calc(50% - 2.5rem);
    font-size: 5rem;
    color: rgba(0, 0, 0, 0.454);
    cursor: pointer;
}


.feed-header{
    display: flex;
    width: 90%;
    height: 4rem;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    border-bottom: 1px solid grey;
}
.feed-header-posttype{
    display: flex;
    width: 70%;
    font-size: 1rem;
    align-items: center;
    
}
.feed-header-avatar{
    display: flex;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: var(--product-tag-bg);
    color: var(--product-tag-color);
    border-radius: 5px;
}
.feed-header-avatar img{
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.feed-body{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    overflow-x: hidden; 

}
.feed-body-detail{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
    font-size: 1rem;
    margin-inline: auto;
    
}
.download-images{
    display: flex;
    margin-top: 20px;
}
.download-body-detail{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
    font-size: 1rem;
    border-bottom: 1px solid grey;
    
}
.download-container{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 80%;
    align-items: flex-start;
    margin: 0 auto;
}

.feed-body-img{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding:0;
    margin:0;
}

.refresh {
    position: fixed;
    top: -50px;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
  }
  
  .refresh.active {
    transform: translateY(50px);
  }


.css-19kzrtu{
    padding: 0 !important;
}
.feed-back-icon{
    padding-right: 15px;
}
.feed-body-image{
    display: flex;
    -ms-touch-action: none;
    touch-action: auto;
    position: relative;
    width: 100%;
    max-height: 600px;
    object-fit: contain;
    pointer-events: auto;
}

.fullscreen-feed{
    position: absolute;
    z-index: 100000000;
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    justify-content: center;
    align-items: center;
}


.feed-body-image-modal{
    display: flex;
    position: relative;
    width: 100%;
    max-height: 600px;
    object-fit: contain;
}


.feed-footer{
    display: flex;
    flex-direction: column;
    width: 90%;
}
.feed-body-title{
    display: flex;
    padding-block: 0.9rem;
    font-size: 1rem;
    font-weight: 500;
}
.feed-footer-detail{
    display: flex;
    justify-content: space-between;
    font-size: smaller;
    padding-block: 0.9rem;
    font-size: smaller;
    width: 100%;
}
.feed-body-markdown{
    padding-block: 10px;
}
.feed-body-markdown p{
    margin-block: 5px;
}

.feed-markdown p{
    margin: 0;
}
.feed-footer-markdown-container{
    position: relative;
}

/* .feed-footer-markdown-container button{ } */

.feed-readmore{
    color: var(--ion-color-text);
    background-color: transparent;
    text-decoration: underline;
}
.feed-footer-bkmrk{
    display: flex;
    width: 50%;
    border-top: 1px solid grey;
    margin: 0 auto;
    justify-content: space-around;
    padding-block: 1rem;
    margin-top: 0.5rem;
}
.feed-footer-bkmrk-btn{
    display: flex;
    width: 50px;
    height: 50px;
    padding:1rem;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    box-shadow: 0px 0px 5px 1px var(--ion-color-step-100);
    cursor: pointer;
}
.feed-footer-pdf-btn{
    display: flex;
    width: 50px;
    height: 50px;
    padding:1rem;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    box-shadow: 0px 0px 5px 1px var(--ion-color-step-100);
    cursor: pointer;
}
.markdown-truncated{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.read-more{
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    font-size: 12px
}
.read-more-visible{
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: small;
    height: 3rem;
}
.read-more-clicked{
    font-weight: bold;
    margin-block: 20px;
    padding-left: 10px;
}
.read-more-btn{
    font-size: small;
}

.read-less-btn{
    margin-bottom: 1px;
    margin-top: 1px;
    padding-block: 6px;
    padding-inline: 10px;
}


ion-card{
    --background:"grey",
    --color: "red"
}

.header-cont{
    display: flex;
    height: 5rem;
}