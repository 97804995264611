
ion-modal.cat-modal-container{
    --ion-safe-area-top: var(safe-area-inset-top) !important;
    padding-top: 50px !important;
}
.cat-top{
    display: flex;
    width: 100%;
    height: 10vh;
    align-items: center;
    padding-left: 20px;
    color: black;
    font-weight: bold;
    font-size: 20px;
}
.cat-menu{
    display: flex;
    width: 100%;
    height: 90vh;
    background-color: antiquewhite;
}

.cat-header-title{
    font-weight: 600;
}


.cat-feed-container{
    color : var(--ion-color-dark);
}

.cat-container{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 10px auto;
    align-items: center;
}
.cat-body{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.cat-item{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    background: var(--ion-color-step-50);
    border: 2px solid var(--ion-color-step-50);
}
.cat-item-img{
    display: none;
}


.cat-item-footer{
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    padding-block: 1rem;
    border-top: 1px solid var(--ion-color-step-100);

}
.cat-item-title{
    width: 60%;
    font-weight: 600;
}
.cat-item-right{
    min-width: 40px ;
    max-height: 40px;
}
.cat-item-right img{
    min-width: 30px;
    max-height: 30px;
}

@media only screen and (min-width:768px){
    .cat-body{
        display: flex;
        flex-direction: row;
        width: 80%;
        flex-wrap: wrap;
        margin-top: 4rem;
        justify-content: space-around;
        
    }
    .cat-item{
        display: flex;
        width: 40%;
        height: auto;
        margin-block: 1.5rem;
        cursor: pointer;
        border-radius: 20px;
        border: 0.5px solid grey;
    }
    .cat-item:nth-child(odd){
        margin-right: auto;
    }
    .cat-item-img{
        display: block;
        width: 100%;
        height: 300px;
        border-radius: 20px;
        
    }

    .cat-item-img img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        
    }
}


/* Modal */

.cat-modal{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.cat-modal-header{
    display: flex;
    position: fixed;
    width: 100%;
    height: 5rem;
    padding-left: 1rem;
    z-index: 100000000;
    align-items: center;
    background: var(--ion-color-step-50);
}

.cat-modal-header-title{
    color: grey;
    
}

.cat-header{
    display: flex;
    width: 80%;
    height: 5rem;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid var(--ion-text-color);
    border-radius: 5px;
    padding-left: 2rem;
}
.cat-header-title{
    display: flex;
    width: 30%;
    

}
.cat-header-avatar{
    display: flex;
    width: 30%;
}
.cat-modal-body{
    width: 100%;
    height: auto;
    padding-top: 5rem;
    overflow-y: scroll;
    background: var(--ion-color-light);
    color: var(--ion-color-dark);
}

@media only screen and (max-width:768px){
    .cat-header{
        display: none;
    }
}